
import store from "@/store";
import {Button, Searchbar, Table} from "@/ui";
import { defineComponent } from "vue";
import { EmployeeController } from "@/controller/";

export default defineComponent({
    name: "EmployeeList",
    components: { Button, Searchbar, Table },
    created(){
        this.handleGetEmployees()
    },
    data(){
        return{
            store,
            employeeTableData:{
                thFields: [{
                    title: this.$t("employee.number"), property: "number", type: "string", clickAction: "openDetail" 
                }, { 
                    title: this.$t("employee.firstname"), property:"firstName", type: "string",  clickAction: "openDetail"
                }, {
                    title: this.$t("employee.lastname"), property: "lastName", type: "string", clickAction: "openDetail"
                }, {
                    title: this.$t("employee.nfcChipId"), property: "nfcChipId", type: "string", clickAction: "openDetail"
                }, { 
                    title: this.$t("label.department"), property: "department", type: "string"
                }],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }, { name: "editEmployee", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove }]
            },
            employees: [] as Array<any>,
            searchInput: ""
        }
    },
    computed: {
        filteredEmployees() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.employees.forEach((employee : any) => {
                    JSON.stringify(employee.firstName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                    !filtered.includes(employee) && JSON.stringify(employee.lastName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                })
            } else {
                filtered = this.employees;
            }
            return filtered;
        }
    },
    methods:{
        async handleGetEmployees() {
            const res = await EmployeeController.fetchEmployees();
            if(!res.error){
                this.employees = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            console.log("handleTable", action, item)
            switch(action) {
                case "openDetail":
                    this.openDetailView(item.uid);
                    break
                case "confirmDelete":
                    this.confirmDeleteEmployee(item);
                    break
                case "editEmployee":
                    this.editEmployee(item.uid);
                    break
            }
        },
        handleShowCreateForm(){
            this.$router.push("/administration/employee/form/0");
        },
        openDetailView(uid : number) {
            this.$router.push("/administration/employee/detail/" + uid);
        },
        editEmployee(uid:number) {
            this.$router.push("/administration/employee/form/" + uid);
        },
        confirmDeleteEmployee(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t("confirm.deleteEmployeeText", { name: item.firstName + item.lastName }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {
            const res = await EmployeeController.deleteEmployee(uid);
            if (!res.error) {
                this.employees = this.employees.filter(function(item : any) {
                   return item.uid != res.employee.uid;
                });
                this.$notify(this.$t("success.employeeDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
